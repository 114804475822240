<script lang="ts" setup>
const route = useRoute();
</script>

<template>
  <div>
    <Navbar />
    <v-main>
      <div>
        <div>
          <slot />
        </div>
        <div class="container mx-auto" v-if="route.meta.page_append?.includes('places')">
          <Places class="border-t border-gray-tonal pt-16" />
        </div>
      </div>
    </v-main>

    <Footer />
    <CustomerAuthLoginGoogle />
  </div>
</template>
